<template>
  <div class="px-5">
    <Form
      title="Editar academia"
      ref="academyForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
      :deletable="true"
      @updateContact="updateContact"
      @removeAcademyCategory="removeAcademyCategory"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      response: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/academy",
          module: "academy",
          id: this.$route.params.id
        })
        .then((response) => {
          this.response = { ...response.data }
          let formx = response.data
          this.$refs.academyForm.form = formx
          this.$refs.academyForm.form.categories = formx.categories.map(
            (x) => x.id_category + "$$" + x.id_subcategory
          )
          this.$refs.academyForm.academyContacts = formx.contacts
          for (let index in formx.contacts)
            formx.contacts[index].key = formx.contacts[index].id

          this.$refs.academyForm.visible =
            response.data && response.data.active === 1 ? true : false

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility() {
      this.$store
        .dispatch("global/changeStatus", {
          payload: {
            id: this.$refs.academyForm.form.id,
            status: !this.$refs.academyForm.visible ? 1 : 0
          },
          route: "/academy",
          module: "academy"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-status", response.code, this.$snotify)
          })
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    updateContact(contact) {
      this.$store
        .dispatch("global/update", {
          payload: {
            id: contact.id,
            name: contact.name,
            phone: contact.phone
          },
          route: "/academy_contact/update"
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    createNewContacts() {
      const newContacts = this.$refs.academyForm.academyContacts.filter(
        (x) => !x.id
      )
      for (let contact of newContacts) {
        this.$store
          .dispatch("global/create", {
            payload: {
              id_academy: this.$route.params.id,
              name: contact.name,
              phone: contact.phone
            },
            route: "/academy_contact/save"
          })
          .catch((error) => {
            console.log("academy contact error:", error)
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      }
    },
    createNewAcademyCategories(categories) {
      const newElements = categories.filter(
        (x) =>
          this.response.categories.findIndex(
            (y) =>
              y.id_category == x.id_category &&
              y.id_subcategory == x.id_subcategory
          ) === -1
      )

      for (let element of newElements) {
        this.$store
          .dispatch("global/create", {
            payload: {
              id_academy: this.response.id,
              id_category: element.id_category,
              id_subcategory: element.id_subcategory
            },
            route: "/academy_category/save"
          })
          .catch((error) => {
            console.log("academy category creation error:", error)
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      }
    },
    removeAcademyCategory(data) {
      // Get element ID
      const index = this.response.categories.findIndex(
        (x) =>
          x.id_category == data.id_category &&
          x.id_subcategory == data.id_subcategory
      )
      if (index !== -1) {
        this.$store
          .dispatch("global/changeStatus", {
            payload: {
              id: this.response.categories[index].id,
              status: 2
            },
            route: "/academy_category"
          })
          .catch((error) => {
            console.log("academy category removal error :", error)
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify)
            })
          })
      }
    },
    save() {
      let form = { ...this.$refs.academyForm.form }
      this.loading = true
      this.createNewContacts()

      form.categories = form.categories.map((x) => ({
        id_category: x.split("$$")[0],
        id_subcategory: x.split("$$")[1]
      }))

      this.createNewAcademyCategories(form.categories)
      this.$store
        .dispatch("global/update", {
          payload: {
            id: form.id,
            coordinator: form.coordinator,
            name: form.name,
            categories: JSON.stringify(form.categories),
            address1: form.address1,
            address2: form.address2,
            email: form.email,
            instagram: form.instagram,
            url_logo: form.url_logo,
            url_field: form.url_field
          },
          route: "/academy/update",
          module: "academy"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master-update", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/academias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
